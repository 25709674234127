import {environment} from "@environments/environment";

export const publicNavigation: any[] = [
  {
    id: 'home',
    title: 'home',
    type: 'basic',
    link: '/home',
  },
  {
    id: 'packages-not-login',
    title: 'packages',
    type: 'basic',
    link: '/packages',
  },
  {
    id: 'support',
    title: 'support',
    type: 'basic',
    callback: () => {
      if ((window as any)['$chatwoot']) {
        (window as any)['$chatwoot'].toggle();
      }
    },
    meta: {
      public: true
    }
  },
  {
    id: 'my-packages',
    title: 'myPackages',
    type: 'basic',
    link: '/my-packages',
    meta: {
      client: true
    }
  },
  {
    id: 'support',
    title: 'support',
    type: 'basic',
    link: '/help-center/empty',
    meta: {
      client: true
    }
  },
  // {
  //   id: 'help-center',
  //   title: 'helpCenter',
  //   type: 'basic',
  //   link: 'https://support.' + environment.domainForUrl + '.com/',
  //   externalLink: true,
  // },
  {
    id: 'blog',
    title: 'blog',
    type: 'basic',
    link: '/blog',
  },
];

export const backendNavigation: any[] = [
  {
    id: 'dashboard',
    title: 'dashboard',
    type: 'basic',
    icon: '',
    link: '/backend/dashboard',
  },
  {
    id: 'users',
    title: 'users',
    type: 'basic',
    icon: '',
    link: '/backend/users',
  },
  {
    id: 'payments',
    title: 'payments',
    type: 'basic',
    icon: '',
    link: '/backend/payments'
  },
  {
    id: 'orders',
    title: 'orders',
    type: 'basic',
    icon: '',
    link: '/backend/orders'
  },
  {
    id: 'pages',
    title: 'pages',
    type: 'basic',
    icon: '',
    link: '/backend/pages'
  },
  {
    id: 'posts',
    title: 'posts',
    type: 'basic',
    icon: '',
    link: '/backend/posts'
  }
];
