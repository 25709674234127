import {Component} from '@angular/core';
import {COMMON} from "@shared/contstants/common.const";
import {LayoutService} from "../../../layout.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent{

  logoPatch = COMMON.LOGO_PATCH;

  constructor(public _layoutService: LayoutService) {
  }

}
