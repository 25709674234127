import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {environment} from '@environments/environment';

import {UserService} from '../../layout/common/user/user.service';
import {StorageUtilsService} from '../utils/storage.utils.service';
import {Router} from '@angular/router';
import {SplashScreenService} from "@core/utils/splash-screen.service";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";

@Injectable()
export class AuthService {
  // Private
  _authenticated: boolean;
  _isLogin: boolean = false;

  private url: string = environment.apiUrl;
  _authType: EventEmitter<any> = new EventEmitter();

  _resetPassData: {
    email: string | null,
    code: string | null
  } = {email: null, code: null};
  tg: any;
  fb: any;

  typeGoggleConnect: string = 'auth';
  _authGoogle: EventEmitter<any> = new EventEmitter();


  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param userService
   * @param _storageUtilsService
   * @param _splashScreenService
   * @param _notificationUtilsService
   * @param _router
   * @param ngZone
   */
  constructor(
    private _httpClient: HttpClient,
    private userService: UserService,
    private _storageUtilsService: StorageUtilsService,
    private _splashScreenService: SplashScreenService,
    private _notificationUtilsService: NotificationUtilsService,
    private _router: Router,
    private ngZone: NgZone
  ) {
    // Set the defaults
    this._authenticated = false;
    // this.initFacebook();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem(environment.authTokenKey, token);
  }

  get accessToken(): string {
    return localStorage.getItem(environment.authTokenKey) || '';
  }

  set authType(value: any) {
    this._authType.emit(value);
  }

  get authType$(): Observable<any> {
    return this._authType.asObservable();
  }

  get hasAccessToken(): boolean {
    return !!this.accessToken?.length;
  }

  removeToken(key: string): void {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }

  setToken(token: string): void {
    // Store the access token in the local storage
    this.accessToken = token;

    // Set the authenticated flag to true
    this._authenticated = true;

    this.userService.loadUser().subscribe();
    // this.getProfilePermissions().subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   * @param type
   */
  signIn(credentials: any, type?: string): Observable<any> {
    // Throw error, if the user is already logged in
    if (this.hasAccessToken) {
      this._authenticated = true;
      return throwError({isAlreadyLogin: true});
    }

    // Reset support chat
    if ((window as any)['$chatwoot']) {
      (window as any)['$chatwoot'].reset();
    }
    const request = type?.length ?
      this._httpClient.post(this.url + '/api/public/auth/social_callback/' + type, credentials) :
      this._httpClient.post(this.url + '/api/public/auth/login', credentials)
    return request.pipe(
      switchMap((response: any) => {
        this.setToken(response.access_token);
        return of(response);
      })
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signUp(credentials: any): Observable<any> {
    return this._httpClient.post(this.url + '/api/public/auth/registration', credentials).pipe(
      switchMap((response: any) => {
        this.setToken(response.access_token);
        return of(response);
      })
    );
  }

  loginById(id: string): Observable<any> {
    if (this.hasAccessToken) {
      this._authenticated = true;
      return throwError({isAlreadyLogin: true});
    }
    return this._httpClient.post(this.url + '/api/public/auth/login_by_id', {id}).pipe(
      switchMap((response: any) => {
        this.setToken(response.access_token);
        return of(response);
      })
    );
  }

  resetPassword(data?: any): Observable<any> {
    if (data?.email) {
      this._resetPassData.email = data.email;
    }
    return this._httpClient.post(this.url + '/api/public/auth/password_reset_request ', this._resetPassData);
  }

  authCodeRequest(data?: any): Observable<any> {
    if (data?.email) {
      this._resetPassData.email = data.email;
    }
    return this._httpClient.post(this.url + '/api/public/auth/auth_code_request ', this._resetPassData);
  }

  loginByAuthCode(data: any): Observable<any> {
    if (data?.code) {
      this._resetPassData.code = data.code;
    }
    return this._httpClient.post(this.url + '/api/public/auth/login_by_auth_code', this._resetPassData).pipe(
      switchMap((response: any) => {
        if (response?.access_token?.length) {
          this.setToken(response.access_token);
        }
        return of(response);
      })
    );
  }

  resetPasswordValidate(data: any): Observable<any> {
    if (data?.code) {
      this._resetPassData.code = data.code;
    }
    return this._httpClient.post(this.url + '/api/public/auth/password_reset_validate', this._resetPassData);
  }

  recoveryPassword(data: any): Observable<any> {
    return this._httpClient.post(this.url + '/api/public/auth/password_reset', {...this._resetPassData, ...data}).pipe(
      switchMap((response: any) => {
        if (response?.access_token?.length) {
          this.setToken(response.access_token);
        }
        return of(response);
      })
    );
  }

  registerAffiliate(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlPublic()}/auth/register_affiliate`, data)
      .pipe(
        switchMap((res: any) => {
          this.setToken(res.access_token);
          return of(res);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Reset support chat
    if ((window as any)['$chatwoot']) {
      (window as any)['$chatwoot'].reset();
    }

    // Remove the access token from the local storage
    this.removeToken(environment.authTokenKey);
    this.userService.removeUser();
    this._storageUtilsService.clear();
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (this.hasAccessToken) {
      this._authenticated = true;
      return of(true);
    }

    return of(false);
  }

  loginBySocial(data: any, type: string): Observable<any> {
    if (this.hasAccessToken) {
      this._authenticated = true;
      return throwError({isAlreadyLogin: true});
    }
    return this._httpClient.post(this.url + '/api/public/auth/social_callback/' + type, data).pipe(
      switchMap((response: any) => {
        this.setToken(response.access_token);
        return of(response);
      })
    );
  }

  handleCredentialResponse(response: any) {
    // Here we can do whatever process with the response we want
    // Note that response.credential is a JWT ID token
    console.log("Encoded JWT ID token: " + response.credential);
    this.ngZone.run(() => {
      const request = this.typeGoggleConnect === 'auth' ?
        this.loginBySocial({token: response.credential}, 'google') :
        this.addSocials({token: response.credential}, 'google');

      request.subscribe((res: any) => {
          this._authGoogle.emit({success: true});
        }, (err: any) => {
          this._authGoogle.emit({success: false});
        }
      );
    })
  }

  initGoogle() {
    const google = (window as any)['google']
    // 1054636676525-u1qhjcre4osai8l64ssphuifj7ga2l8u.apps.googleusercontent.com - old
    // 880851186215-vt7jc1hn2kvkro4hffsdbsfpqke0u91q.apps.googleusercontent.com - new
    google.accounts.id.initialize({
      use_fedcm_for_prompt: true,
      client_id: "1054636676525-u1qhjcre4osai8l64ssphuifj7ga2l8u.apps.googleusercontent.com", // Replace with your Google Client ID
      callback: this.handleCredentialResponse.bind(this) // We choose to handle the callback in client side, so we include a reference to a function that will handle the response
    });
    if (!this.hasAccessToken) {
      google.accounts.id.prompt(); // Display the One Tap dialog
    }
  }

  renderGoogleButton() {
    const google = (window as any)['google']
    // You can skip the next instruction if you don't want to show the "Sign-in" button
    google.accounts.id.renderButton(
      document.getElementById("buttonGoogle"), // Ensure the element exist and it is a div to display correcctly
      {theme: "dark", size: "large"}  // Customization attributes
    );
  }

  addSocials(data: any, type: string): Observable<any> {
    const name = type[0].toUpperCase() + type.slice(1, type.length)
    return this.userService.addSocial(data, type).pipe(tap(_ => {
      this._notificationUtilsService.showActionNotification(name + ' login successfully added', 1);
    }, _ => {
      this._notificationUtilsService.showActionNotification('Unable to log in via ' + name, 0);
    }));
  }

  // initTg() {
    // if (!this._authService.hasAccessToken) {
    // this._storageUtilsService.loadJs('https://telegram.org/js/telegram-widget.js?22', 'telegram-script', 'Telegram')
    //   .then(async _ => {
    //     this.tg = window && (window as any)?.Telegram;
    //     console.log(this.tg);
    //   });
    // }
  // }

  loginTg(s: any, isConnect = false, dialogRef?: any, cdr?: any) {
    cdr.markForCheck();
    this.tg.Login.auth(
      {bot_id: environment.tgBotId, request_access: 'write', size: 'large'}, (data: any) => {
        if (!data) {
          s.loading = false;
          cdr.markForCheck();
          this._notificationUtilsService.showActionNotification('Unable to log in via Telegram', 0);
          return;
        }
        if (isConnect) {
          this.addSocials(data, 'telegram').subscribe(res => {
            s.connect = true;
            s.loading = false
            cdr.markForCheck();
          }, (err: any) => {
            s.loading = false;
            cdr.markForCheck();
          });
        } else {
          this.signIn(data, 'telegram').subscribe(_ => {
            s.loading = false;
            cdr.markForCheck();
            dialogRef.close();
          }, err => {
            s.loading = false;
            cdr.markForCheck();
            const text = err.error || 'Unable to log in via Telegram'
            this._notificationUtilsService.showActionNotification(text, 0);
          })
        }
      }
    );
  }

  initApple(listener: any) {
    this._storageUtilsService.loadJs('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', 'apple-script', 'AppleID')
      .then(async _ => {
        const AppleID = (window as any)['AppleID'];
        if (!AppleID) {
          return;
        }
        const domain = window.location.origin;
        AppleID.auth.init({
          clientId: environment.appleClientId,
          scope: 'name email',
          redirectURI: domain,
          usePopup: true
        });

        AppleID.auth.renderButton()

        // Listen for authorization success.
        document.addEventListener('AppleIDSignInOnSuccess', listener, false);

      })
  }

  // initFacebook(callback: any) {
  //   this._storageUtilsService.loadJs('https://connect.facebook.net/en_US/sdk.js', 'facebook-script', 'FB')
  //     .then(async _ => {
  //       this.fb = (window as any)['FB'];
  //       if (!this.fb) {
  //         return;
  //       }
  //       this.fb.init({
  //         appId: environment.facebookAppId,
  //         cookie: true,
  //         xfbml: true,
  //         version: 'v8.0'
  //       });
  //       callback();
  //     })
  // }

  // getFbPick(){
  //   this.fb.api(
  //     '/me',
  //     { fields: 'id,name,picture.type(large)' }, // Request the fields you need
  //     (response: any) => {
  //       if (response && !response.error) {
  //         console.log(response);
  //         const img = response.picture.data.url;
  //         console.log('User picture:', response.picture.data.url);
  //         alert('User photo URL: ' + response.picture.data.url);
  //       } else {
  //         console.error('Error fetching user data:', response.error);
  //       }
  //     }
  //   );
  // }

  // loginFb(s: any, isConnect = false, dialogRef?: any, callback?: any) {
  //   // this.fb.login((response: any) => {
  //   this.fb.Event.subscribe('auth.statusChange', (response: any) => {
  //     if (response?.status === 'connected') {
  //       if (response.authResponse) {
  //         if (isConnect) {
  //           this.addSocials(response.authResponse, 'facebook').subscribe(res => {
  //             s.connect = true;
  //             callback(false);
  //           }, (err: any) => {
  //             callback(false);
  //           });
  //         } else {
  //           this.signIn(response.authResponse, 'facebook').subscribe(_ => {
  //             // this.getFbPick();
  //             dialogRef.close();
  //             callback(false);
  //           }, err => {
  //             const text = err.error || 'Unable to log in via Facebook'
  //             this._notificationUtilsService.showActionNotification(text, 0);
  //             callback(false);
  //           })
  //         }
  //       } else {
  //         const text = 'Unable to log in via Facebook'
  //         this._notificationUtilsService.showActionNotification(text, 0);
  //         callback(false);
  //       }
  //     } else {
  //       callback(false);
  //     }
  //   });
  // }

}
