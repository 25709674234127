<div class="menu-container transition"
     ngClass="{{!_layoutService.navSidebarClass ? 'hide' : ''}}">
  <div class="main-sidebar">
    <div class="sidebar-brand">
      <a routerLink="/" class="my-3">
        <img [src]="logoPatch" alt="logo" width="160" height="5">
      </a>
    </div>
    <div class="sidebar-inner">
      <ul class="{{lang === 'en' ? 'en' : ''}}">
        <ng-container *ngFor="let item of links; let i = index">
            <ng-container *ngIf="!item.meta || (item.meta?.public && !isHasToken) || (item.meta?.client && isHasToken)">
              <li [ngClass]="{'is-active': isActive(item)}"
                  class="{{item?.class}}" style="{{item.style}}">
                <ng-container *ngIf="item?.callback; else route">
                  <a (click)="item.callback(item)">
                    {{item.title }}
                  </a>
                </ng-container>
                <ng-template #route>
                  <ng-container *ngIf="item.externalLink; else defRoute">
                    <a href="{{item.link}}">
                      {{item.title }}
                    </a>
                  </ng-container>
                  <ng-template #defRoute>
                    <a routerLink="{{item.link}}">
                      {{item.title }}
                    </a>
                  </ng-template>
                </ng-template>
              </li>
            </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
