export const COMMON: any = {
  CHANNEL_TYPES: ['mail', 'sms', 'telegram'],
  NOTIFICATIONS: ['waiting', 'processing', 'completed', 'cancelled'],
  COOKIE_KEY: ['bb_config'],
  EMAIL_REGEX: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/,
  LOGO_PATCH: 'assets/images/logo/betsbeats_min.png',
  SITE_SECTION: ['backend', 'profile', 'mobile'],
  SITE_SECTION_CONTROL: 'control',
  SITE_SECTION_BACKEND: 'backend',
  PRIORITIES: [{id: 0, name: 'low'}, {id: 1, name: 'medium'}, {id: 2, name: 'high'}],

  MIME_TYPES: ['image/bmp', 'image/gif', 'image/x-freehand', 'image/x-icon', 'image/jpeg', 'image/png', 'image/tiff'],

  LANGUAGE_LIST: [
    {code: 'en', label: 'English', moment: 'en', format: 'en-US'},
    {code: 'ja', label: '日本', moment: 'ja', format: 'ja-JP'},
    {code: 'es', label: 'Español', moment: 'es', format: 'es-ES'},
  ],

  TABLE: {
    PARAMS: {
      // 'created_at[orderBy]': 'desc'
      order: 'desc',
      orderBy: 'created_at',
      per_page: 50
    },
    ORDER_PARAMS: {
      order: 'asc',
      orderBy: 'order',
      per_page: 50
    },
    DEFAULT_SORT: {
      ACTIVE: 'created_at',
      DIRECTION: 'desc',
    },
    ID_PARAMS: {
      order: 'desc',
      orderBy: 'id',
      per_page: 100
    },
    PAGINATOR: {
      PAGE_SIZE: 50,
      PAGE_OPTIONS: [50, 100, 200, 500]
    },
    SORT: {
      DIRECTIONS: [
        {
          id: 'asc',
          name: 'Ascending'
        },
        {
          id: 'desc',
          name: 'Descending'
        }
      ]
    }

  },

  PRODUCTS: [
    {
      name: 'FLASH 1-DAY ACCESS',
      slug: 'pr_1day',
      id: 'pr_1day',
      total: 0,
      image: 'assets/images/home/top-tipsters-con-bg-1.png',
      image_thumb: "assets/images/betsbeats-1.png",
      description: "Quick access to expert picks from multiple sports. Get top selections for today’s best bets and make the most of your day.",
      localText: 'Best for quick single-day bets.',
      product_attributes: [
        {
          "id": "atr_1day",
          "name": "1-Day Access",
          "type": "day",
          "total": "30.00",
          data: {
            days: 1
          }
        }
      ]
    },
    {
      name: 'BLITZ 3-DAY ACCESS',
      slug: 'pr_3day',
      id: 'pr_3day',
      total: 0,
      image: 'assets/images/home/top-tipsters-con-bg-2.png',
      image_thumb: "assets/images/betsbeats-3.png",
      description: "Enjoy a 3-day streak of expert picks across a variety of sports. Short-term access to winning selections for quick results.",
      localText: 'Save 10% compared to daily price!',
      product_attributes: [
        {
          "id": "atr_8o0OFtidm3b6Qzi8",
          "name": "3-Day Access",
          "type": "day",
          "total": "80.00",
          data: {
            days: 3
          }
        }
      ]
    },
    {
      name: 'STREAK 7- DAY ACCESS',
      slug: 'pr_7day',
      id: 'pr_7day',
      total: 0,
      image: 'assets/images/home/top-tipsters-con-bg-3.png',
      image_thumb: "assets/images/betsbeats-7.png",
      description: "Get one week of winning picks across several sports. Handpicked predictions to keep you ahead throughout the week.",
      localText: 'Save 15% and bet smarter all week.',
      product_attributes: [
        {
          "id": "atr_8o0OFtidm3b6Qzi8",
          "name": "7-Day Access",
          "type": "day",
          "total": "180.00",
          data: {
            days: 7
          }
        }
      ]
    },
    {
      name: 'RUSH 30-DAY ACCESS',
      slug: 'pr_30day',
      id: 'pr_30day',
      total: 0,
      image: 'assets/images/home/top-tipsters-con-bg-4.png',
      image_thumb: "assets/images/betsbeats-30.png",
      description: "Unlock a full month of expert sports picks. Access comprehensive betting strategies and selections to maximize your monthly returns.",
      localText: 'Save 30% for maximum value monthly!',
      product_attributes: [
        {
          "id": "atr_8o0OFtidm3b6Qzi8",
          "name": "30-Day Access",
          "type": "day",
          "total": "500.00",
          data: {
            days: 30
          }
        }
      ]
    },
  ]
};
