import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from 'app/layout/common/user/user.types';
import {UserService} from 'app/layout/common/user/user.service';
import {AuthService} from "@core/auth/auth.service";

import {StorageUtilsService} from "@core/utils/storage.utils.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  // hasAdminToken: boolean = false;
  user: User = {
    email: '',
    id: '',
    first_name: '',
    phone: '',
    role_id: null,
    last_name: '',
  };
  profileLink = '/profile';

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Router} _router
   * @param {UserService} _userService
   * @param _authService
   * @param _storageUtilsService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
    public _storageUtilsService: StorageUtilsService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  get isAdmin() {
    return this.user.role_id === 1;
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        // this.hasAdminToken = this._authService.hasAdminAccessToken;
        this.user = user;
        this._changeDetectorRef.markForCheck();
      });
    // this.hasAdminToken = this._authService.hasAdminAccessToken;
    // this._route.data.subscribe((data: any) => {
    //     this.profileLink = data.layout === 'futuristic' ? '/backend/profile' : '/profile';
    // });
  }

  goDashboard(): void {
    this._router.navigate(['/backend/dashboard']);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // backAdminUser(): void {
  //     this._authService.changeActiveUser();
  // }

  /**
   * Sign out
   *
   */
  signOut(): void {
    this._authService.signOut();`
    this._router.navigate(['auth/sign-in']);`
  }
}
