import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import {RouterModule} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { UserComponent } from 'app/layout/common/user/user.component';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
    declarations: [
        UserComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
    ],
    exports     : [
        UserComponent
    ]
})
export class UserModule
{
}
