<!-- Container -->
<div class="container">

  <app-menu [type]="'backend'" class="backend"></app-menu>

  <div class="wrapper">
    <app-header></app-header>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>

