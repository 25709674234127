import {Component, Input} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthService} from "@core/auth/auth.service";
import {backendNavigation} from "@core/config/app.config";
import {COMMON} from "@shared/contstants/common.const";
import {LayoutService} from "../../../layout.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input() type: string = ''

  links: {
    id: string,
    title: string,
    link: string | undefined,
    class: string,
    permission: string,
    externalLink?: boolean,
    style?: string,
    callback?: any
    meta?: any
  }[] = [];
  logoPatch = COMMON.LOGO_PATCH;

  isHasToken = this._authService.hasAccessToken;
  currentHref: string = '';
  lang: string | undefined;

  constructor(router: Router,
              private _route: ActivatedRoute,
              public _layoutService: LayoutService,
              private _authService: AuthService,
              private location: Location) {

    if (this._layoutService.isXs) {
      this._layoutService._navSidebarClass = true;
    }
    this.setLinks();

    this.currentHref = this.location.path().split('?')[0];
    router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationEnd: {
          this.currentHref = this.location.path().split('?')[0];
          if (this._layoutService.isXs) {
            this._layoutService.closeSidebar();
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  setLinks() {
    this.links = backendNavigation;
  }

  isActive(item: any): boolean {
    if (!this.currentHref?.length) {
      return false;
    }
    return (item.link && this.currentHref === item.link);
  }

}
