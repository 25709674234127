/** Angular */
import {ChangeDetectorRef, Injectable} from '@angular/core';

import {FormGroup} from '@angular/forms';

import moment from 'moment';

import _ from 'lodash';

@Injectable()
export class FormUtilsService {

  constructor() {
  }

  getTimeAgo(date: any): string {
    return !!date ? moment(date).fromNow() : '';
  }


  getErrorMessage(form: any, controlName: string | any = ''): any {
    const control = controlName?.length ? form.controls[controlName] : form;
    let translateKey = control.hasError('required') ? 'required' : '';

    if (control.hasError('pattern')) {
      if (control?.errors?.pattern?.requiredPattern === '^[0-9]{1,100}$') {
        translateKey = 'patternMin';
      } else if (controlName.includes('domain')) {
        translateKey = 'patternDomain';
      } else {
        translateKey = 'patternLink';
      }
    }
    if (control.hasError('minlength')) {
      return `The text field must be at least ${control?.errors?.minlength?.requiredLength} characters.`
    }
    if (control.hasError('maxlength')) {
      return `Maximum field length: ${control?.errors?.maxlength?.requiredLength}`
    }
    if (control.hasError('min')) {
      return `Minimum value: ${control?.errors?.min?.min}`
    }
    if (control.hasError('max')) {
      return `Maximum value: ${control?.errors?.max?.max}`
    }
    if (control.hasError('matDatetimePickerMin')) {
      translateKey = 'matDatetimePickerMin';
    }
    if (control.hasError('email')) {
      return 'Not a valid email';
    }
    if (control.hasError('validatePhoneNumber')) {
      translateKey = 'validatePhoneNumber';
    }
    if (control.hasError('mustMatch') && (controlName === 'email' || controlName === 'confirm_email')) {
      translateKey = 'mustMatchEmail';
    } else if (control.hasError('mustMatch')) {
      return 'Password and confirm password do not match';
    }
    if (control.hasError('serverError')) {
      return control.getError('serverError');
    }
    if (control.hasError('mustObj')) {
      translateKey = 'mustObj';
    }
    if (control.hasError('wrongEnding')) {
      translateKey = 'wrongEnding';
    }
    return 'This field is required';
  }

  prepareServerError(error: any = null, form: FormGroup, cdr: ChangeDetectorRef, textErrors?: any): any {
    if (!error) {
      return;
    }
    let errors = error.errors;
    if (!errors && error.error && typeof error.error !== 'string') {
      errors = error.error;
    }
    if (errors) {
      Object.keys(errors).map(field => {
        let error = '';
        if (_.has(errors, field)) {
          const values = _.get(errors, field, '');
          if (values?.length) {
            for (const value of values) {
              error += value;
            }
          }
        }
        if (textErrors) {
          textErrors.push(error);
        }
        if (form && form.controls[field]) {
          form.controls[field].setErrors({'serverError': error});
        } else if (field?.length && form) {
          const fieldObjPatch = field.split('.').join('.controls.');
          if (_.has(form.controls, fieldObjPatch)) {
            const control = _.get(form.controls, fieldObjPatch, '');
            if (control) {
              control.setErrors({'serverError': error});
            }
          }
        }
      });
      // Mark for check
      if (cdr) {
        cdr.markForCheck();
      }
      const message = error.message ? error.message : 'The given data was invalid.';
      return this.getMessage(message);
    } else {
      // Show the error message
      let message = 'The given data was invalid.';
      if (error.message && typeof error.message === 'string') {
        message = error.message;
      } else if (error.error && error.error === 'string') {
        message = error.error;
      } else if (error && error === 'string') {
        message = error;
      }
      return this.getMessage(message);
    }
  }

  getUrl(item: any, defaultUrl: string): string {
    return defaultUrl + item.slug;
  }


  mustMatch(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getMessage(message: string, type: number = 0): any {
    return {
      message: message,
      type: type
    };
  }

  normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  formatDate(date: any, format?: string, hideAgo?: boolean, isUnix?: boolean): string | null {
    if (isUnix) {
      date = moment.unix(date);
    }
    if (format === 'human_readable') {
      return !!date ? moment(date).fromNow(hideAgo) : '';
    }
    const f = format?.length ? format : 'YYYY/MM/DD HH:mm';
    if (date) {
      return moment(date).format(f);
    }
    return null;
  }

  validation(form: any, collapse = false, collapseName = '', arrCollapse?: any[]): void {
    const controls = form.controls;
    Object.keys(controls).forEach(controlName => {
      if (collapse && controls[controlName].invalid && arrCollapse) {
        arrCollapse.push(controlName);
      }
      if (controls[controlName].controls) {
        this.validation(controls[controlName], controlName === collapseName, controlName, arrCollapse);
      }
      controls[controlName].markAsTouched();
    });
  }

  /**
   * d - data - дані, які потрібно перевіряти
   * formatDate
   * checkParent - чи перевіряти обєкт, після видалення пустих дочірніх елементів
   * pD - parentData - дані, що потрібно повторно перевірити
   * pF - parentField - полу, яке потрібно повторно перевірити
   *
   */
  checkEmptyField(d: any, formatDate: string = 'YYYY-MM-DD HH:mm', checkParent = false, pD: string | null = null, pF: string | null = null): void {
    Object.keys(d).forEach((f: string) => {
      if ((!d[f] || (Object.prototype.toString.call(d[f]) === '[object Object]' &&
          this.isEmptyObject(d[f])) || (Array.isArray(d[f]) && !d[f]?.length))
        && d[f] !== 0 && d[f] !== false) {
        delete d[f];
      } else if (Object.prototype.toString.call(d[f]) === '[object Object]') {
        if (checkParent) {
          this.checkEmptyField(d[f], formatDate, true, d, f);
        } else {
          this.checkEmptyField(d[f], formatDate);
        }
      } else if (typeof d[f] === 'boolean') {
        d[f] = d[f] ? 1 : 0;
      } else if (f?.length && f.includes('d_at')) {
        d[f] = moment(d[f]).format(formatDate);
      }
    });
    if (pD && pF) {
      this.checkEmptyField(pD, formatDate, false, null);
    }
  }

  isEmptyObject(obj: any): boolean {
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        return false;
      }
    }
    return true;
  }

  selectFilter(selectArray: any, value: string, searchWithGroup?: any, groupField?: any, searchKey = ''): any[] {
    const filterValue = this.normalizeValue(value);
    let searchArray: any[] = [];
    if (!selectArray?.length) {
      return [];
    }
    selectArray.map((s: any) => {
      if (typeof s === 'string') {
        searchArray.push(s);
      } else {
        const item = Object.assign({}, s);
        searchArray.push(item);
      }
    });
    if (searchArray?.length >= 1 && !searchKey?.length) {
      searchKey = searchArray[0]['name']?.length ? 'name' : searchArray[0]['label']?.length ? 'label' : '';
    }
    if (!searchKey?.length) {
      return searchArray.filter(item => {
        return this.normalizeValue(item.toString()).indexOf(filterValue) > -1;
      });
    } else {
      if (searchWithGroup) {
        const a = searchArray.filter(item => {
          item[groupField] = item[groupField].filter((s: any) => {
            const name = _.get(s, searchKey) || '';
            return this.normalizeValue(name).indexOf(filterValue) > -1;
          });
          return item[groupField]?.length;
        });
        return a;
      }
      return searchArray.filter(item => {
        const name = _.get(item, searchKey) || '';
        return this.normalizeValue(name).indexOf(filterValue) > -1;
      });
    }
  }

  getArrayWithGroup(searchArray: any, selectArray: any, groupField: any): void {
    selectArray.map((el: any) => {
      if (el && el[groupField] && el[groupField].length) {
        searchArray.push(el);
        this.getArrayWithGroup(searchArray, el[groupField], groupField)
      } else {
        searchArray.push(el);
      }
    })
  }

  searchMulti(searchArray: any, value: string, groupField: string, key: string): any[] {
    const d = this.search(searchArray, this.normalizeValue(value), groupField, key);
    return d.items;
  }

  search(searchArray: any, value: string, groupField: string, key: string): any {
    let allHide = true;
    const d = searchArray.map((item: any) => {
      item.hide = item[key].toLowerCase().indexOf(value) <= -1;
      if (!item.hide && allHide) {
        allHide = false;
      }
      if (item[groupField]?.length && item.hide) {
        const d = this.search(item[groupField], this.normalizeValue(value), groupField, key);
        item[groupField] = d.items;
        item.hide = d.allHide;
        if (!item.hide) {
          allHide = false;
        }
      } else {
        this.setChildNotHide(item[groupField], groupField)
      }
      return item;
    });
    return {items: d, allHide};
  }
  setChildNotHide(searchArray: any, groupField: string): void {
    searchArray.map((item: any) => {
      item.hide = false;
      if (item[groupField]?.length) {
        this.setChildNotHide(item[groupField], groupField)
      }
    });
  }
}
