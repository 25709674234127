import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {VerticalComponent} from "./vertical.component";
import {CommonModule} from "@angular/common";
import {HeaderComponent} from "./header/header.component";
import {MatIconModule} from "@angular/material/icon";
import {UserModule} from "../../common/user/user.module";
import {MenuComponent} from "./menu/menu.component";

@NgModule({
  declarations: [
    VerticalComponent,
    HeaderComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    UserModule,
  ],
    exports     : [
        VerticalComponent
    ]
})
export class VerticalModule
{
}
