<!--<app-menu></app-menu>-->

<div class="header">

  <div class="header-container">
    <div class="flex">
      <div class="header-brand">
        <a routerLink="/">
          <img [src]="logoPatch" alt="logo" width="56" height="56">
        </a>
      </div>
      <div class="hamburger">
        <mat-icon [svgIcon]="'menu'"
                  class="is-show"
                  (click)="_layoutService.toggleSidebarClass()"></mat-icon>
      </div>
    </div>

    <div class="right">
      <app-user></app-user>
    </div>
  </div>
</div>
