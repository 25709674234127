import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

import {AuthService} from 'app/core/auth/auth.service';

import {Interceptor} from './utils/intercept.service';
import {SplashScreenService} from "@core/utils/splash-screen.service";
import {messaging} from "@core/utils/firabase.config";


@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    SplashScreenService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    { provide: 'messaging', useValue: messaging}
  ]
})
export class CoreModule {
  /**
   * Constructor
   *
   * @param {DomSanitizer} _domSanitizer
   * @param {MatIconRegistry} _matIconRegistry
   * @param _splashScreenService
   * @param parentModule
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
    private _splashScreenService: SplashScreenService,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }

    // Register icon sets

    this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg?v=1'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg?v=1'));
  //
  }
}
