
<ng-container *ngIf="user && user.id">
    <button class="icon-button"
            mat-icon-button
            [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="user?.avatar"
             [src]="user.avatar">
        <mat-icon *ngIf="!user?.avatar"
                  [svgIcon]="'account_circle'"></mat-icon>
    </span>
    </button>
    <mat-menu class="user-actions-menu"
              [xPosition]="'before'"
              #userActions="matMenu">
        <button mat-menu-item>
        <span class="user-info">
            <span>{{'Signed In As'}}</span>
            <span class="email">{{user.email}}</span>
        </span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="profileLink">
            <mat-icon [svgIcon]="'settings'"></mat-icon>
            <span>{{'Account Dashboard'}}</span>
        </button>
        <ng-container *ngIf="_storageUtilsService.isBackend && isAdmin">
            <mat-divider></mat-divider>
            <button mat-menu-item
                    [routerLink]="'/home'">
                <mat-icon svgIcon="mat_outline:dashboard"></mat-icon>
                <span>{{'Client Dashboard'}}</span>
            </button>
        </ng-container>
        <ng-container *ngIf="_storageUtilsService.isClient">
            <mat-divider></mat-divider>
            <button mat-menu-item
                    (click)="goDashboard()">
                <mat-icon svgIcon="mat_outline:list"></mat-icon>
                <span>{{'Backend Dashboard'}}</span>
            </button>
        </ng-container>
    </mat-menu>
</ng-container>
