<header class="header-section" #header
        appCheckScroll [globalScroll]="true"
        (scrollEmit)="changeClassForHeader($event)">
  <div class="container">
    <div class="row d-flex header-area">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/">
          <img [src]="logoPatch" class="logo" alt="logo" height="55" width="215">
        </a>
        <button class="navbar-toggler collapsed" type="button"
                (click)="toggleMenu()">
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse justify-content-end" #navbarContent>
          <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
            <li class="nav-item" id="home">
              <a class="nav-link" routerLink="/">Home</a>
            </li>
            <li class="nav-item" id="offers">
              <a class="nav-link" routerLink="/offers">Offers</a>
            </li>
            <li class="nav-item" id="tips">
              <!--              <a class="nav-link" routerLink="/" [queryParams]="{f: 's'}">Get Tips</a>-->
              <a class="nav-link" routerLink="/tips">Tips</a>
            </li>
            <li class="nav-item" id="about">
              <a class="nav-link" routerLink="/about">About</a>
            </li>
            <li class="nav-item" id="faq">
              <a class="nav-link" routerLink="/faq">FAQ</a>
            </li>
            <li class="nav-item" id="contact-us">
              <a class="nav-link" routerLink="/contact-us">Contact</a>
            </li>
          </ul>
          <div class="right-area header-action d-flex align-items-center max-un">
            <ng-container *ngIf="_authService.hasAccessToken; else loginBtn">

              <app-menu-notification class="me-2 border-left"></app-menu-notification>

              <ng-container *ngIf="user?.balance?.length">
                <a routerLink="/profile/deposit" class="balance link mx-2">
                  <span>{{ user.balance | currency }}</span>
                </a>
              </ng-container>

              <a routerLink="/profile" class="max-w ms-2">
                <ng-container *ngIf="user?.photo?.length || user?.photo_thumb?.length; else gravatar">
                  <img class="avatar" src="{{user.photo_thumb || user.photo}}" alt="avatar" width="36" height="36">
                </ng-container>
                <ng-template #gravatar>
                  <img ngxGravatar [email]="user.email || 'test@email.com'" [size]="36" alt="" src=""/>
                </ng-template>
              </a>
            </ng-container>
            <ng-template #loginBtn>
              <button type="button" class="login border-left" (click)="showModal('logIn')">
                Login
              </button>
              <button type="button" class="cmn-btn reg"  (click)="showModal('signUp')">
                Join for Free
              </button>
            </ng-template>
            <!--              <button type="button" class="cmn-btn reg" data-bs-toggle="modal" data-bs-target="#loginMod">-->
            <!--                Join-->
            <!--              </button>-->
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
<div class="overlay" id="menu-overlay" (click)="toggleMenu()"></div>
