import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'vertical-layout',
    templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit{

    /**
     * Constructor
     *
     */
    constructor() {}

    /**
     * On init
     */
    ngOnInit(): void {
    }

}
