import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from 'app/layout/layout.component';
import {EmptyLayoutModule} from 'app/layout/layouts/empty/empty.module';
import {CenteredLayoutModule} from './layouts/horizontal/centered/centered.module';
import {LayoutService} from "./layout.service";
import {VerticalModule} from "./layouts/vertical/vertical.module";

const modules = [
  // Empty
  EmptyLayoutModule,
  // Horizontal navigation
  CenteredLayoutModule,
];

@NgModule({
  declarations: [
    LayoutComponent
  ],
    imports: [
        CommonModule,
        ...modules,
        VerticalModule
    ],
  providers: [
    LayoutService
  ],
  exports: [
    ...modules
  ]
})
export class LayoutModule {
}
